// custom-list
ul.custom-list {
    @extend .list-unstyled;
    li {
        position: relative;
        padding: 0 0 0 1.5rem;
        &::before {
            content: '\f00c';
            position: absolute;
            top: 0;
            left: 0;
			font-family: $font-awesome;
            color: $primary;
            font-weight: 900;
        }
    }
}

// custom-list-extra
ul.custom-list-extra {
    @extend .list-unstyled;
    li {
        position: relative;
        padding: 0 0 0 1.5rem;
        &::before {
            content: '\f058';
            position: absolute;
            top: 0;
            left: 0;
			font-family: $font-awesome;
            color: $primary;
            font-weight: 400;
        }
    }
}
