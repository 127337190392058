// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $tertiary;
	box-shadow: $shadow;
	transition: 0.5s;
	&.sticky {
		.logo-large {
			top: -350px;
		}
	}

	[class^="container-"] {
		.container-holder {
			padding-left: calc((100vw / 2 - 590px));
			> .column {
				@extend .align-items-center;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}
				@extend .navbar-expand-xl;
			}
		}
	}

	// logo-large
	.logo-large {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 54px;
		width: 160px;
		height: 180px;
		padding: 10px 0 0 15px;
		@include media-breakpoint-up(xl) {
			left: 30px;
			width: 230px;
			height: 270px;
			padding: 25px 0 0 30px;
		}
		background: $secondary;
		border-radius: 0 0 50% 50%;
		transition: 0.5s;
		a {
			width: 120px;
			@include media-breakpoint-up(xl) {
				width: 169px;
			}
			@extend .d-block;
			img {
				@extend .w-100;
			}
		}
	}

	// logo-small
	.logo-small {
		width: 56px;
		margin: 12px auto 12px 0;
		@include media-breakpoint-up(xl) {
			width: 71px;
		}
		a {
			@extend .d-block;
			img {
				@extend .w-100;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-right: 15px;
		.navbar-toggler {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			height: 24px;
			padding: 0;
			color: $gray;
			font-size: 24px;
			text-align: center;
			transition: 0.5s;
			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
			.navbar-toggler-icon {
				height: 24px;
			}
		}
	}

	// menu
	.menu {
		.navbar-nav {
			margin-left: auto;
		}
		@include media-breakpoint-down(lg) {
			order: 4;
			.navbar-nav {
				.nav-item {
					font-size: 16px;
					font-weight: 700;
					.nav-link {
						color: $gray;
						&:hover {
							color: $primary;
						}
					}
					&.active {
						> .nav-link {
							color: $primary;
						}
					}
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						background-color: transparent;
						.nav-item {
							line-height: normal;
							&.active {
								.nav-link {
									color: $primary;
								}
							}
						}
					}
				}
			}
		}
		@include media-breakpoint-up(xl) {
			.navbar-nav {
				> .nav-item {
					margin: 0 5px;
					font-size: 16px;
					font-weight: 700;
					.nav-link {
						color: $gray;
						&:hover {
							color: $primary;
						}
					}
					&.active {
						> .nav-link {
							color: $primary;
						}
					}
				}
				.nav-item {
					.dropdown-menu {
						.nav-item {
							line-height: normal;
							&.active {
								.nav-link {
									color: $primary;
								}
							}
						}
					}
				}
			}
		}
	}

	// social
	.social {
		ul {
			margin-left: 15px;
			li {
				a {
					display: block;
					padding: 0.5rem;
					color: $gray;
					span {
						display: none;
					}
					&:hover {
						color: $primary;
					}
				}
			}
		}
	}

	div.book-button {
		order: 3;
		flex: 0 0 100%;
		margin-bottom: 10px;

		@include media-breakpoint-up(sm) {
			order: unset;
			flex: none;
			margin-bottom: 0;
		}

		ul.book-button {
			justify-content: flex-end;

			li {
				a {
					@extend .btn;
					@extend .btn-primary;

					padding: 0.375rem 1.125rem;
					border-radius: 99px;
					font-size: 14px;
					
					@include media-breakpoint-up(sm) {
						font-size: 16px;
						padding: 0.375rem 1.25rem;
					} 

					&::after {
						content: '\f061';
						font-family: $font-awesome;
						font-weight: 300;
						margin-left: 10px;
					}
				}
			}
		}
	}

	&.sticky {
		div.book-button {
			order: unset;
			flex: none;
			margin-bottom: 0;
		}
	}

	// languages
	.languages {
		margin-left: 20px;
	}
}

// home
&.home {
	.header {
		position: fixed;
	}
}
