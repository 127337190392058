// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// container-one-column
	.container-one-column {
		.container-holder {
			p:last-of-type {
				margin-bottom: 0;
			}
		}

		&.intro {
			.container-holder {
				max-width: 750px;
				margin-bottom: 30px;
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin-right: 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.discount-price {
			.amount {
				margin-right: 10px;
				font-size: $font-size-lg;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.price {
			.amount {
				margin-right: 10px;
				font-size: $font-size-lg;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.former-price {
			text-decoration: line-through;

			.amount {
				margin-right: 10px;
				font-size: $font-size-sm;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}
	}
}

&.home {
	.lead-section {
		@include media-breakpoint-down(md) {
			margin-top: 0;
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// main-collection-section
// =========================================================================
.main-collection-section {
	position: relative;
	margin: 8vh 0;

	&::before {
		content: "";
		position: absolute;
		top: 70px;
		left: 0;
		right: 0;
		bottom: 140px;
		background: $tertiary;
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					.card {
						background: none;
						border-radius: 0;
						border: none;

						.card-body {
							background: $green;
							border-radius: 0 0 50vw 50vw;
							padding: 0 0 45px 0;

							.card-caption {
								padding: 30px;

								.card-title {
									@include font-size($h1-font-size);
									color: $white;
									line-height: 1;

									&:hover {
										color: $green-dark;
									}
								}

								.card-subtitle {
									color: $green-dark;
								}

								.card-description {
									color: $green-dark;
									font-weight: 700;
									text-transform: uppercase;
								}
							}

							.card-buttons {
								.card-btn {
									padding: 15px 30px;
									border-radius: 50vw;
									color: $white;
									font-size: 18px;
									font-weight: 700;

									&::after {
										content: "\f061";
										margin-left: 15px;
										font-weight: 300;
										font-family: $font-awesome;
										transition: 0.5s;
									}

									&:hover {
										&::after {
											margin-left: 18px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	position: relative;
	margin: 8vh 0;

	&::before {
		content: "";
		position: absolute;
		top: 150px;
		left: 0;
		right: 0;
		bottom: 150px;
		background: $tertiary;
	}

	// collection
	.collection {
		margin-top: 30px;

		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						border: none;
						border-radius: 0;
						background: none;

						.card-body {
							position: relative;
							padding: 0;

							.card-caption {
								.card-title {
									position: absolute;
									left: 0;
									bottom: 100%;
									margin: 0 20px 20px 0;
									padding: 20px;
									background: $primary;
									border-radius: 0 50vw 50vw 0;
									color: $white;
									font-size: $h5-font-size;

									&::after {
										content: "\f061";
										margin-left: 15px;
										font-weight: 300;
										font-family: $font-awesome;
										transition: 0.5s;
									}

									&:hover {
										&::after {
											margin-left: 18px;
										}
									}
								}

								.card-subtitle {
									margin-top: 15px;
									font-size: 16px;
								}

								.card-description {
									margin-top: 15px;
								}
							}
						}

						&:hover {
							.card-body {
								.card-caption {
									.card-title {
										background: $secondary;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	margin-top: 8vh;
	margin-bottom: calc(8vh + 80px);
	padding-left: calc((100vw / 2 - 562.5px));

	// owl-carousel
	.owl-carousel {
		height: 50vh;
		min-height: 300px;
		max-height: 720px;

		.owl-stage-outer {
			padding-bottom: 80px;
		}

		.item {
			height: 50vh !important;
			/* overide */
			min-height: 300px;
			max-height: 720px;
			padding: unset;
			border-radius: 50vw 0 0 50vw;
			align-items: flex-end;

			.owl-caption {
				max-width: 540px;
				height: 160px;
				margin: 0 0 -80px auto;
				padding: 20px 45px;

				@include media-breakpoint-up(xl) {
					padding: 30px 60px;
				}

				background: $quaternary;
				border-radius: 50vw;
				text-shadow: none;
				text-align: left;

				.owl-title {
					font-size: 18px;

					@include media-breakpoint-up(xl) {
						font-size: $h4-font-size;
					}
				}

				.owl-description {
					p {
						margin-bottom: 0;
					}
				}
			}
		}

		.owl-dots,
		.owl-nav {
			display: none;
		}
	}
}

// tiles-collection-section
// =========================================================================
.tiles-collection-section {
	position: relative;
	margin: 8vh 0;

	&::before {
		content: "";
		position: absolute;
		top: 300px;
		left: 0;
		bottom: 300px;
		width: 99%;
		background: $tertiary;
		border-radius: 0 50vw 50vw 0;
	}

	// intro
	.intro {
		.container-holder {
			max-width: 750px;
		}
	}

	// collection
	.collection {
		margin-top: 30px;

		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						border: none;
						background: none;
						border-radius: 0;
						box-shadow: 6px 6px 12px rgba($black, 0.15);

						.card-img-overlay {
							border-radius: 0;

							@include media-breakpoint-up(xl) {
								padding: 30px;
							}

							.card-caption {
								text-shadow: none;

								.card-title {
									@include font-size($h2-font-size);
								}

								.card-subtitle,
								.card-description {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

// icon-collection-section
// =========================================================================
.icon-collection-section {
	position: relative;
	margin: 8vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include make-col(6);

					@include media-breakpoint-up(sm) {
						@include make-col(4);
					}

					@include media-breakpoint-up(md) {
						@include make-col(3);
					}

					@include media-breakpoint-up(lg) {
						@include make-col(2);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(2);
					}

					.card {
						word-wrap: normal;
						@include media-breakpoint-up(xl) {
							min-height: 340px;
						}

						padding: 40px 0;
						border: none;
						border-radius: 50vw;
						background: $green;

						&:hover {
							background: $tertiary;
						}

						.card-image {
							flex: 1 1 auto;
							margin-bottom: 15px;
						}

						.card-body {
							.card-caption {
								text-align: center;

								.card-title {
									color: #394553;
									font-size: 18px;
								}

								.card-subtitle,
								.card-description {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

// outro-section
// =========================================================================
.outro-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					.card {
						@extend .bg-light;
						border: none;
						border-radius: 0;

						.card-body {
							.card-caption {
								.card-title {
									&:hover {
										color: $primary;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}
