// footer
.footer {
	margin-top: 6vh;
	padding: 60px 0 30px 0;

	// footer-outro
	.footer-outro {
		background: $secondary;
		padding: 30px 0;

		// container-three-columns
		.container-three-columns {
			.column {
				&.one {
					flex: 0 0 auto;
					width: auto;
					max-width: 100%;
					.logo {
						margin-top: -100px;
						width: 120px;
						@include media-breakpoint-up(md) {
							width: 169px;
						}
						a {
							@extend .d-block;
							img {
								@extend .w-100;
							}
						}
					}
				}
			}
			@include media-breakpoint-up(md) {
				.column {
					&.two,
					&.three {
						flex: 1 1 0;
						width: auto;
						max-width: unset;
					}
				}
			}
		}

		// tel/mailto
		a {
			color: $white;
			font-weight: 500;
			text-decoration: none;
			&:hover {
				color: $black;
			}
			&[href^="tel:"] {
				&::before {
					content: "\f095";
					font-family: $font-awesome;
					display: inline-block;
					width: 30px;
					font-weight: 300;
				}
			}
			&[href^="mailto:"] {
				&::before {
					content: "\f0e0";
					font-family: $font-awesome;
					display: inline-block;
					width: 30px;
					font-weight: 300;
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			.list {
				.list-item {
					margin-right: 15px;
					font-size: 30px;
					line-height: 1;
					.link {
						display: block;
					}
				}
			}
		}
	}

	// footer-copyright
	.footer-copyright {
		padding: 30px 0;

		// footer-link
		.footer-link {
			.list {
				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}
				justify-content: center;
				text-align: center;
				.list-item {
					@include media-breakpoint-up(md) {
						margin: 0 15px;
					}
					font-size: 13px;
					.link {
						color: $gray;
						text-decoration: none;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	// footer-partners
	.footer-partners {
		// footer-text
		.footer-text {
			ul {
				@extend .list;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				li {
					margin: 7.5px 15px;
				}
			}
		}
	}

	// contact-badge
	div.contact-badge {
		@include media-breakpoint-down(lg) {
			display: none;
		}
		a {
			z-index: 999;
			position: fixed;
			right: 15px;
			bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			width: 90px;
			height: 150px;
			padding: 15px 5px 5px 5px;
			border-radius: 45px 45px 0 0;
			background: $primary;
			color: $white;
			font-size: 15px;
			font-weight: 500;
			text-align: center;
			line-height: normal;
			text-decoration: none;
			overflow: hidden;
			&::after {
				content: "\f062";
				font-family: $font-awesome;
				font-size: 18px;
				font-weight: 300;
				transition: 0.5s;
			}
			&:hover {
				background: $secondary;
			}
		}
	}
}
