// home
&.home {
	h2 {
		@include font-size($h1-font-size);
	}
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// link-arrow
a.link-arrow {
	color: $primary;
	font-weight: 500;
	text-decoration: none;

	&::after {
		content: "\f061";
		margin-left: 10px;
		font-weight: 300;
		font-family: $font-awesome;
		transition: margin 0.5s;
	}

	&:hover {
		color: darken($primary, 15%);

		&::after {
			margin-left: 15px;
		}
	}
}


&.nothumbnail-cards .card-image {
	display: none !important;
}
