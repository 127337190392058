// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}
.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 540px;
	max-height: 1080px;
	@include media-breakpoint-up(sm) {
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	.owl-carousel {
		.item {
			align-items: flex-end;
			padding-bottom: 200px;
			@include media-breakpoint-up(md) {
				padding-bottom: 40px;
			}
			.owl-caption {
				@include media-breakpoint-up(md) {
					margin-right: 350px;
				}
				text-align: left;
				text-shadow: none;
				.owl-title {
					@include font-size($h1-font-size);
					@include media-breakpoint-up(xl) {
						font-size: 60px;
					}
				}
				.owl-subtitle {
					max-width: 420px;
					margin-left: 30px;
					@include font-size($h2-font-size);
					@include media-breakpoint-up(xl) {
						font-size: 40px;
					}
				}
			}
		}
	}
}
