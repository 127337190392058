// mini-sab
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;
		.container-holder {
			z-index: 100;
			position: relative;
			padding: 0;
			background: $white;
			border-radius: 50px;
			width: 100%;
			max-height: max-content;
			min-height: 85px;
			margin: -85px;
			align-items: center;
			top: -40vh;
			@include media-breakpoint-down(lg) {
				padding: 1.5rem 0.5rem;
				top: -26vh;
			}
		}
		.booking-experts {
			form {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				padding: 0 5px;
				@include media-breakpoint-down(lg) {
					gap: 0.5rem;
				}
				input {
					max-width: 24%;
					flex-basis: 24%;
					height: 50px;
					border-radius: 50px;
					padding: 1.25rem;
					border: 1px solid #000;
					background: #fff;
					font-size: 16px;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
					position: relative;
					&[type="date"]:not(.has-value):before {
						color: black;
						content: attr(placeholder);
						background: $white;
						position: absolute;
						left: 1.25rem;
						height: 100%;
						width: 50%;
						pointer-events: none;
					}
					&[type="date"]::-webkit-calendar-picker-indicator {
						background: transparent;
						bottom: 0;
						color: transparent;
						cursor: pointer;
						height: auto;
						left: 0;
						position: absolute;
						right: 0;
						top: 0;
						width: auto;
					}
					&:focus {
						border: 1px solid $primary;
						outline: 1px solid $primary;
					}
					&[type="submit"] {
						@extend .btn, .btn-primary;
						border-radius: 50px;
					}
					@include media-breakpoint-down(lg) {
						max-width: 100%;
						flex-basis: 100%;
					}
				}
			}
		}
	}
}
